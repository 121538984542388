/* eslint-disable unicorn/filename-case */
export const SET_ADDRESSES = 'SET_ADDRESSES';
export const SET_INVOICE = 'SET_INVOICE';

export const SET_WRITE_INVOICE = 'SET_WRITE_INVOICE';
export const SET_WRITE_INVOICE_ACCOUNTING_PERIOD = 'SET_WRITE_INVOICE_ACCOUNTING_PERIOD';
export const SET_WRITE_INVOICE_ACTION = 'SET_WRITE_INVOICE_ACTION';
export const SET_WRITE_INVOICE_ADDRESS = 'SET_WRITE_INVOICE_ADDRESS';
export const SET_WRITE_INVOICE_ADDRESS_TYPE = 'SET_WRITE_INVOICE_ADDRESS_TYPE';
export const SET_WRITE_INVOICE_DATE = 'SET_WRITE_INVOICE_DATE';
export const SET_WRITE_INVOICE_DESCRIPTION = 'SET_WRITE_INVOICE_DESCRIPTION';
export const SET_WRITE_INVOICE_INVOICE_STATUS_ID = 'SET_WRITE_INVOICE_INVOICE_STATUS_ID';
export const SET_WRITE_INVOICE_RELATION = 'SET_WRITE_INVOICE_RELATION';
export const SET_WRITE_INVOICE_SEND_DATE = 'SET_WRITE_INVOICE_SEND_DATE';
export const SET_WRITE_INVOICE_SUBTOTAL = 'SET_WRITE_INVOICE_SUBTOTAL';
export const SET_WRITE_INVOICE_TAX_AMOUNT = 'SET_WRITE_INVOICE_TAX_AMOUNT';
export const SET_WRITE_INVOICE_TOTAL = 'SET_WRITE_INVOICE_TOTAL';

export const ADD_WRITE_INVOICE_INVOICE_LINE = 'ADD_WRITE_INVOICE_INVOICE_LINE';
export const SET_WRITE_INVOICE_INVOICE_LINES_AMOUNT = 'SET_WRITE_INVOICE_INVOICE_LINES_AMOUNT';
export const SET_WRITE_INVOICE_INVOICE_LINES_PRODUCT = 'SET_WRITE_INVOICE_INVOICE_LINES_PRODUCT';
export const SET_WRITE_INVOICE_INVOICE_LINES_PRODUCT_CODE = 'SET_WRITE_INVOICE_INVOICE_LINES_PRODUCT_CODE';
export const SET_WRITE_INVOICE_INVOICE_LINES_PRODUCT_ID = 'SET_WRITE_INVOICE_INVOICE_LINES_PRODUCT_ID';
export const SET_WRITE_INVOICE_INVOICE_LINES_PRODUCT_NAME = 'SET_WRITE_INVOICE_INVOICE_LINES_PRODUCT_NAME';
export const SET_WRITE_INVOICE_INVOICE_LINES_PRODUCT_PRICE = 'SET_WRITE_INVOICE_INVOICE_LINES_PRODUCT_PRICE';
export const SET_WRITE_INVOICE_INVOICE_LINES_SUBTOTAL = 'SET_WRITE_INVOICE_INVOICE_LINES_SUBTOTAL';
export const SET_WRITE_INVOICE_INVOICE_LINES_TAX_RATE = 'SET_WRITE_INVOICE_INVOICE_LINES_TAX_RATE';
export const UNSET_WRITE_INVOICE_INVOICE_LINE = 'UNSET_WRITE_INVOICE_INVOICE_LINE';
