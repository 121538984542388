import { GET_ADDRESSES, GET_INVOICE, GET_WRITE_INVOICE } from './-getter-types';
import { useInvoicesStore } from '~/stores/invoices';
import type { Invoice } from '~/models/Invoice';
import type { RelationAddress } from '~/models/RelationAddress';
import type { WriteInvoiceDto } from '~/types/Invoice';

const Getters = {
    [GET_ADDRESSES]: (): RelationAddress | null => {
        const invoicesStore = useInvoicesStore();

        return invoicesStore.addresses;
    },
    [GET_INVOICE]: (): Invoice | null => {
        const invoicesStore = useInvoicesStore();

        return invoicesStore.invoice;
    },
    [GET_WRITE_INVOICE]: (): WriteInvoiceDto | null => {
        const invoicesStore = useInvoicesStore();

        return invoicesStore.writeInvoice;
    },
};

export default Getters;
