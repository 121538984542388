import { GET_TAX_RATE_BY_ID } from '../tax-rates/-getter-types';
import {
    ADD_WRITE_INVOICE_INVOICE_LINE,
    SET_ADDRESSES,
    SET_INVOICE,
    SET_WRITE_INVOICE,
    SET_WRITE_INVOICE_ACCOUNTING_PERIOD,
    SET_WRITE_INVOICE_ACTION,
    SET_WRITE_INVOICE_ADDRESS,
    SET_WRITE_INVOICE_ADDRESS_TYPE,
    SET_WRITE_INVOICE_DATE,
    SET_WRITE_INVOICE_DESCRIPTION,
    SET_WRITE_INVOICE_INVOICE_LINES_AMOUNT,
    SET_WRITE_INVOICE_INVOICE_LINES_PRODUCT,
    SET_WRITE_INVOICE_INVOICE_LINES_PRODUCT_CODE,
    SET_WRITE_INVOICE_INVOICE_LINES_PRODUCT_ID,
    SET_WRITE_INVOICE_INVOICE_LINES_PRODUCT_NAME,
    SET_WRITE_INVOICE_INVOICE_LINES_PRODUCT_PRICE,
    SET_WRITE_INVOICE_INVOICE_LINES_SUBTOTAL,
    SET_WRITE_INVOICE_INVOICE_LINES_TAX_RATE,
    SET_WRITE_INVOICE_INVOICE_STATUS_ID,
    SET_WRITE_INVOICE_RELATION,
    SET_WRITE_INVOICE_SEND_DATE,
    SET_WRITE_INVOICE_SUBTOTAL,
    SET_WRITE_INVOICE_TAX_AMOUNT,
    SET_WRITE_INVOICE_TOTAL,
    UNSET_WRITE_INVOICE_INVOICE_LINE,
} from './-mutation-types';
import { GET_ADDRESSES, GET_WRITE_INVOICE } from './-getter-types';
import { useInvoicesStore } from '~/stores/invoices';
import InvoiceService from '~/services/api/InvoiceService';

import RelationService from '~/services/api/RelationService';
import type { WriteInvoiceDto } from '~/types/Invoice';
import { AddressTypeEnum } from '~/enums/AddressTypeEnum';

import type { InvoiceActionEnum } from '~/enums/InvoiceActionEnum';
import type { Invoice } from '~/models/Invoice';
import { InvoiceFactory } from '~/models/factories/InvoiceFactory';
import { Address } from '~/models/Address';
import { defaultWriteInvoiceData } from '~/stores/invoices/state';
import type { Product } from '~/models/Product';
import { useTaxRatesStore } from '~/stores/tax-rates';

const Actions = {
    async fetchInvoice(invoiceId: number) {
        const invoicesStore = useInvoicesStore();
        const invoiceService = new InvoiceService();
        const response = await invoiceService.fetchInvoice(invoiceId);

        invoicesStore[SET_INVOICE](response);
    },
    setInvoice(invoice: Invoice | null) {
        const invoicesStore = useInvoicesStore();
        invoicesStore[SET_INVOICE](invoice);
    },

    /*
     * Write invoice methods
     */
    setWriteInvoice(invoice: WriteInvoiceDto | null) {
        const invoicesStore = useInvoicesStore();
        invoicesStore[SET_WRITE_INVOICE](invoice ?? defaultWriteInvoiceData());
    },
    setWriteInvoiceAccountingPeriod(accountingPeriod: number) {
        const invoicesStore = useInvoicesStore();
        invoicesStore[SET_WRITE_INVOICE_ACCOUNTING_PERIOD](accountingPeriod);
    },
    setWriteInvoiceAction(action: InvoiceActionEnum) {
        const invoicesStore = useInvoicesStore();
        invoicesStore[SET_WRITE_INVOICE_ACTION](action);
    },
    setWriteInvoiceAddress(address: string) {
        const invoicesStore = useInvoicesStore();
        invoicesStore[SET_WRITE_INVOICE_ADDRESS](address);

        // If address is mutated manueally, set address type to custom
        if (invoicesStore[GET_WRITE_INVOICE]?.addressType !== AddressTypeEnum.CUSTOM) {
            invoicesStore[SET_WRITE_INVOICE_ADDRESS_TYPE](AddressTypeEnum.CUSTOM);
        }
    },
    // eslint-disable-next-line max-statements
    setWriteInvoiceAddressType(addressType: AddressTypeEnum) {
        const invoicesStore = useInvoicesStore();
        invoicesStore[SET_WRITE_INVOICE_ADDRESS_TYPE](addressType);

        // Set preferred address
        if (addressType !== AddressTypeEnum.CUSTOM) {
            // Get all relations addresses from store
            const addresses = invoicesStore[GET_ADDRESSES];

            if (!addresses) {
                return;
            }

            // Determine preferred relation's address
            const address = addresses.addresses.find(address => address.invoiceAddressType === addressType);

            // Stringify the address
            let addressStringified = '';
            if (address) {
                addressStringified = Address.stringifyAddress(address, '\n');
            }

            invoicesStore[SET_WRITE_INVOICE_ADDRESS](addressStringified);
        }
    },
    setWriteInvoiceDate(date: string) {
        const invoicesStore = useInvoicesStore();
        invoicesStore[SET_WRITE_INVOICE_DATE](date);
    },
    setWriteInvoiceDescription(description: string) {
        const invoicesStore = useInvoicesStore();
        invoicesStore[SET_WRITE_INVOICE_DESCRIPTION](description);
    },
    setWriteInvoiceFromInvoice(invoice: Invoice) {
        const invoicesStore = useInvoicesStore();
        invoicesStore[SET_WRITE_INVOICE]((new InvoiceFactory()).toWriteDto(invoice));
    },
    setWriteInvoiceInvoiceStatusId(invoiceStatusId: number) {
        const invoicesStore = useInvoicesStore();
        invoicesStore[SET_WRITE_INVOICE_INVOICE_STATUS_ID](invoiceStatusId);
    },
    async setWriteInvoiceRelation(relationId: number) {
        const invoicesStore = useInvoicesStore();
        invoicesStore[SET_WRITE_INVOICE_RELATION](relationId);

        // Fetch relation's addresses
        if (relationId) {
            const addressesResponse = await (new RelationService()).fetchRelationAddresses(relationId);

            invoicesStore[SET_ADDRESSES](addressesResponse);

            if (addressesResponse.invoicePreferenceAddress) {
                // Set preferred address type
                invoicesStore.setWriteInvoiceAddressType(
                    addressesResponse.invoicePreferenceAddress as AddressTypeEnum,
                );
            }
        }
    },
    setWriteInvoiceSendDate(sendDate: string) {
        const invoicesStore = useInvoicesStore();
        invoicesStore[SET_WRITE_INVOICE_SEND_DATE](sendDate);
    },
    /* eslint-disable-next-line max-statements */
    setWriteInvoiceTotals() {
        const invoicesStore = useInvoicesStore();
        const invoiceLines = invoicesStore[GET_WRITE_INVOICE]?.invoiceLines;

        if (!invoiceLines) {
            return;
        }

        let subtotal = 0;
        let taxAmount = 0;
        let total = 0;
        for (const invoiceLine of invoiceLines) {
            const itemSubtotalExVat = (invoiceLine.productPrice * invoiceLine.amount);
            subtotal += itemSubtotalExVat;
            taxAmount += (invoiceLine.subtotal - itemSubtotalExVat);
            total += invoiceLine.subtotal;
        }

        invoicesStore[SET_WRITE_INVOICE_SUBTOTAL](subtotal);
        invoicesStore[SET_WRITE_INVOICE_TAX_AMOUNT](taxAmount);
        invoicesStore[SET_WRITE_INVOICE_TOTAL](total);
    },
    /* Invoice line methods */
    // eslint-disable-next-line vue/sort-keys
    addWriteInvoiceInvoiceLine() {
        const invoicesStore = useInvoicesStore();
        invoicesStore[ADD_WRITE_INVOICE_INVOICE_LINE]();
    },
    cleanUpWriteInvoiceEmptyInvoiceLines() {
        const invoicesStore = useInvoicesStore();
        const invoiceLines = invoicesStore[GET_WRITE_INVOICE]?.invoiceLines;

        if (!invoiceLines) {
            return;
        }

        for (const invoiceLine of invoiceLines) {
            if (Number(invoiceLine.amount) === 0 || invoiceLine.product === null) {
                invoicesStore.unsetWriteInvoiceInvoiceLine(invoiceLine.uid);
            }
        }
    },
    setWriteInvoiceInvoiceLinePriceValues(index: number) {
        const invoicesStore = useInvoicesStore();
        const writeInvoice = invoicesStore[GET_WRITE_INVOICE];

        if (!writeInvoice) {
            return;
        }

        const { amount, productPrice, taxRate } = writeInvoice.invoiceLines[index];
        const subtotal = (productPrice * amount) * ((taxRate / 100) + 1);

        invoicesStore[SET_WRITE_INVOICE_INVOICE_LINES_SUBTOTAL]({
            index,
            value: Number(subtotal),
        });
    },
    setWriteInvoiceInvoiceLinesAmount({ index, value }: { index: number; value: number }) {
        const invoicesStore = useInvoicesStore();
        invoicesStore[SET_WRITE_INVOICE_INVOICE_LINES_AMOUNT]({
            index,
            value: Number(value),
        });

        invoicesStore.setWriteInvoiceInvoiceLinePriceValues(index);
        invoicesStore.setWriteInvoiceTotals();
    },
    // eslint-disable-next-line max-statements
    setWriteInvoiceInvoiceLinesProduct({ index, value }: { index: number; value: Product }) {
        const invoicesStore = useInvoicesStore();
        const taxRatesStore = useTaxRatesStore();

        invoicesStore[SET_WRITE_INVOICE_INVOICE_LINES_PRODUCT]({
            index,
            value: value ?? null,
        });
        invoicesStore[SET_WRITE_INVOICE_INVOICE_LINES_PRODUCT_CODE]({
            index,
            value: value.code ?? '',
        });
        invoicesStore[SET_WRITE_INVOICE_INVOICE_LINES_PRODUCT_ID]({
            index,
            value: value.id ?? 0,
        });
        invoicesStore[SET_WRITE_INVOICE_INVOICE_LINES_PRODUCT_NAME]({
            index,
            value: value.name ?? '',
        });
        invoicesStore[SET_WRITE_INVOICE_INVOICE_LINES_PRODUCT_PRICE]({
            index,
            value: Number(value.price ?? 0),
        });

        const rate = taxRatesStore[GET_TAX_RATE_BY_ID](value.taxRateId ?? 0)?.rate;
        const parsedRate = rate ? Number(rate) : 0;
        invoicesStore[SET_WRITE_INVOICE_INVOICE_LINES_TAX_RATE]({
            index,
            value: Number(parsedRate),
        });

        invoicesStore.setWriteInvoiceInvoiceLinePriceValues(index);
        invoicesStore.setWriteInvoiceTotals();
    },
    setWriteInvoiceInvoiceLinesProductPrice({ index, value }: { index: number; value: number }) {
        const invoicesStore = useInvoicesStore();
        invoicesStore[SET_WRITE_INVOICE_INVOICE_LINES_PRODUCT_PRICE]({
            index,
            value: Number(value),
        });

        invoicesStore.setWriteInvoiceInvoiceLinePriceValues(index);
        invoicesStore.setWriteInvoiceTotals();
    },
    unsetWriteInvoiceInvoiceLine(uid: string) {
        const invoicesStore = useInvoicesStore();
        invoicesStore[UNSET_WRITE_INVOICE_INVOICE_LINE](uid);

        return invoicesStore.setWriteInvoiceTotals();
    },
};

export default Actions;
