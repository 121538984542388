/* eslint-disable vue/sort-keys */

import { defineStore } from 'pinia';
import { DefaultState } from './invoices/state';
import Getters from './invoices/getters';
import Actions from './invoices/actions';
import Mutations from './invoices/mutations';

export const useInvoicesStore = defineStore(
    'invoices',
    {
        state: DefaultState,
        getters: Getters,
        actions: {
            ...Actions,
            ...Mutations,
        },
    },
);
